import React from 'react';
import { RouteComponentProps } from '@reach/router';

import Program from './admin/program/share-program';

import { getProgram } from '../apis/public/programs';

type ShareProgramProps = RouteComponentProps & {
  programId?: string;
};

// Public form preview page by share link
const ShareProgram = ({ programId, ...rest }: ShareProgramProps) => {
  return <Program {...rest} programId={programId} getProgram={getProgram} />;
};

export default ShareProgram;
