import React from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from '@reach/router';
import { Row, Col } from 'antd';
import { Spin } from 'antd5';
import styled from '@emotion/styled';

import NotAvailable from '../../../resources/images/not-available.jpg';
import { getProgram as userGetProgram } from '../../../apis/public/programs';
import { IProgram, IStage, StageStatus } from '../../../types/models/program-management';
import { sortStages } from '../../../utils/sort';
import { ImageContainer, ReviewDescriptionBlock } from '../../../components/admin/common/common-styled-component';
import TruncatedTextPopover from '../../../components/common/truncate-text-popover';
import { getProgramImageURL } from '../../../apis/users/programs';
import {
  StageDraftStatusBlock,
  StageClosedStatusBlock,
  StageReadyStatusBlock,
  StagePublishedStatusBlock,
} from '../../../components/admin/common/blocks';

type ProgramProps = RouteComponentProps & {
  programId?: string;
  getProgram?: typeof userGetProgram;
};

const Header = styled(Row)`
  font-size: 32px;
  padding-bottom: 10px;
  margin-top: 10px;
  text-align: center;
`;

const StageContainer = styled(Row)`
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 4px;
  border: solid 1px #d1d3d6;
  padding: 30px 30px;
`;
const StageColumnHeader = styled.div`
  font-size: 14px;
  opacity: 0.5;
  display: inline-block;
  font-weight: bold;
`;

const StageColumnValueName = styled.div`
  font-size: 24px;
  font-weight: bold;
  word-wrap: word-break;
`;
const ReviewBody = styled(Row)`
  background-color: #f2f2f2;
  padding: 45px 80px;
  min-height: 100vh;
`;
const FieldSet = styled.div`
  margin-bottom: 30px;
`;

const FieldLabel = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const InBlock = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding: 0px 20px;
`;

const FieldValue = styled(Row)`
  font-size: 14px;
`;

const LoadingRow = styled.div`
  margin-top: 100px;
  font-size: 32px;
  text-align: center;
`;

const stageStatusDictionary = {
  ready: 'READY TO PUBLISH',
  draft: 'DRAFT',
  closed: 'CLOSED',
  published: 'PUBLISHED',
};

const Program = ({ programId, getProgram = userGetProgram }: ProgramProps) => {
  const {
    data: program,
    isLoading,
    error,
  } = useQuery(
    !!programId && [getProgram.QUERY_KEY, { programId }],
    ({ queryKey }: { queryKey: [string, { programId: string }] }) => getProgram(queryKey[1].programId),
    {
      onSuccess: async (data) => {
        if (program && data?.imageId) {
          const imageUrl = await getProgramImageURL(data?.imageId);
          program.imageUrl = imageUrl;
        }
      },
    }
  );
  const getStatusBlock = (status: StageStatus) => {
    switch (status) {
      case 'closed':
        return <StageClosedStatusBlock>{stageStatusDictionary.closed}</StageClosedStatusBlock>;
      case 'draft':
        return <StageDraftStatusBlock>{stageStatusDictionary.draft}</StageDraftStatusBlock>;
      case 'published':
        return <StagePublishedStatusBlock>{stageStatusDictionary.published}</StagePublishedStatusBlock>;
      case 'ready':
        return <StageReadyStatusBlock>{stageStatusDictionary.ready}</StageReadyStatusBlock>;
    }
  };
  /**
   * GetImageComponent will be implemented once Image Handling Logic is done.
   * @param imageUrl
   */
  const GetImageComponent = (imageUrl: string) => {
    if (!imageUrl) {
      return <ImageContainer src={NotAvailable} alt="Program Image Not Available" />;
    } else {
      return <ImageContainer src={imageUrl} alt="Program Image" />;
    }
  };
  const renderProgramDetail = (program: IProgram) => {
    return (
      <>
        <FieldSet>
          <FieldLabel>Description</FieldLabel>
          <ReviewDescriptionBlock>{program.description}</ReviewDescriptionBlock>
        </FieldSet>
        <FieldSet>
          <FieldLabel>Image</FieldLabel>
          <FieldValue>{GetImageComponent(program.imageUrl || '')}</FieldValue>
        </FieldSet>
      </>
    );
  };

  const renderStageDetail = (stages: IStage[]) => {
    return stages.sort(sortStages).map((stage, index: number) => {
      return (
        <React.Fragment key={index}>
          <Col span={12}>
            <StageContainer>
              <StageColumnHeader>STAGE {index + 1}</StageColumnHeader>
              <InBlock>
                <StageColumnValueName>
                  <TruncatedTextPopover text={name} maxCharCount={20} />
                </StageColumnValueName>
              </InBlock>
              {getStatusBlock(stage.status as StageStatus)}
            </StageContainer>
          </Col>
        </React.Fragment>
      );
    });
  };
  const renderProgram = (program: IProgram) => {
    return (
      <ReviewBody>
        <Header>{program.name}</Header>
        {renderProgramDetail(program)}
        <FieldLabel>Stages</FieldLabel>
        <Row gutter={20} justify="space-around">
          {program.stages && renderStageDetail(program.stages)}
        </Row>
      </ReviewBody>
    );
  };
  if (error) {
    return <p>{error.message}</p>;
  }
  if (isLoading) {
    return (
      <Spin>
        <LoadingRow vertical-align="middle">Program is Loading, please be patient...</LoadingRow>
      </Spin>
    );
  }
  return <>{program && renderProgram(program)}</>;
};
export default Program;
