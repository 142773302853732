import axios from 'axios';

import { TENANT, API_URL } from '../../constants';
import responseParser from '../response-parser';
import { createError } from '../error';
import { IProgram } from '../../types/models/program-management';

/**
 * Get Program: Get Program as the public link
 * @param id
 */
export async function getProgram(id: string): Promise<IProgram> {
  const options = { headers: { tenant: TENANT } };
  const url = `${API_URL}/programs/${id}`;
  try {
    const response = await axios.get(url, options);
    const data: IProgram = responseParser(response);
    return data;
  } catch (err) {
    throw createError(err);
  }
}
getProgram.QUERY_KEY = 'public:getProgram';
