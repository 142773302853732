import React, { memo } from 'react';
import styled from '@emotion/styled';

import { Popover } from '../../components';

const InLine = styled.div`
  display: inline;
`;

const Block = styled.div`
  display: block;
`;
/**
 *
 * @param longText
 * @param maxCharCount
 */
const getTruncatedText = (longText: string, maxCharCount: number) => {
  return longText.substring(0, maxCharCount) + '...';
};

const DEFAULT_CHAR_COUNT = 15;

type RenderStyle = 'inline' | 'block';

type TruncatedTextPopoverProps = {
  text: string;
  maxCharCount?: number;
  style?: RenderStyle;
  popoverStyle?: RenderStyle;
};

const renderText = (style: RenderStyle, text: string) => {
  return style === 'inline' ? <InLine aria-label={text}>{text}</InLine> : <Block aria-label={text}>{text}</Block>;
};

const renderPopOver = (style: RenderStyle, text: string, maxCharCount: number) => {
  return style === 'inline' ? (
    <Popover content={text} title={null} placement="topLeft">
      <InLine aria-label={text}>{getTruncatedText(text, maxCharCount)}</InLine>
    </Popover>
  ) : (
    <Popover content={text} title={null} placement="topLeft">
      <Block aria-label={text}>{getTruncatedText(text, maxCharCount)}</Block>
    </Popover>
  );
};

/**
 * This component is to handle the long text in the title or table cell or one line text
 */
const TruncatedTextPopover = memo(function TruncatedTextPopover({
  text,
  maxCharCount = DEFAULT_CHAR_COUNT,
  style = 'block',
  popoverStyle = 'block',
}: TruncatedTextPopoverProps) {
  return text.length <= maxCharCount ? renderText(style, text) : renderPopOver(popoverStyle, text, maxCharCount);
});

export default TruncatedTextPopover;
