import axios from 'axios';

import { getDocument } from './documents';

import { IPublishedProgram, IRequestReadResponse } from '../../types/models/program-management';
import { API_URL } from '../../constants';
import { createError } from '../error';
import { getBase64 } from '../../utils/common';
import responseParser from '../response-parser';

export async function getPrograms(): Promise<IPublishedProgram[]> {
  const url = `${API_URL}/programs`;

  try {
    const response = await axios.get(url);

    if (response && response.data && response.data.result && response.data.result.programs) {
      return response.data.result.programs;
    } else {
      return [];
    }
  } catch (err) {
    throw createError(err);
  }
}
getPrograms.QUERY_KEY = 'user:getPrograms';

export async function getProgram(id: string): Promise<IPublishedProgram> {
  const url = `${API_URL}/programs/${id}`;

  try {
    const response = await axios.get(url);
    return responseParser(response);
  } catch (err) {
    throw createError(err);
  }
}
getProgram.QUERY_KEY = 'user:getProgram';

/**
 * Get Program Image URL
 * @param imageId
 */
export async function getProgramImageURL(imageId?: string | null) {
  if (imageId) {
    try {
      const image: File = await getDocument(imageId);
      return (await getBase64(image)) as string;
    } catch (e) {
      console.error('Error retrieving program image', e);
    }
  }
  return null;
}
getProgramImageURL.QUERY_KEY = 'user:getProgramImageURL';

/**
 * Get a program's image from /programs/{id}/image endpoint.
 * @param programId The ID of the program.
 */
export async function getProgramImage(programId?: string) {
  if (programId) {
    try {
      const url = `${API_URL}/programs/${programId}/image`;

      let response = await axios.get(url, {
        headers: { accept: 'application/octet-stream' },
        responseType: 'blob',
      });
      let fileName: string;

      if (!response.headers['content-disposition']) {
        const json = JSON.parse(await response.data.text());
        const { url } = json.result;

        fileName = json.result.fileName;
        response = await axios.get(url, {
          headers: { accept: 'application/octet-stream' },
          responseType: 'blob',
        });
      } else {
        fileName = (response.headers['content-disposition'] || '').replace(/.+filename="(.+)"/g, '$1');
      }

      const type = response.headers['content-type'];
      const image = new File([response.data], fileName, { type });
      return (await getBase64(image)) as string;
    } catch (err) {
      const error = createError(err);
      console.error('Error retrieving program image', error);
    }
  }
  return null;
}
getProgramImageURL.QUERY_KEY = 'user:getProgramImage';

/**
 * Reader's request to read new essay
 *
 * @param {string} programId
 * @param {StudentType[]} studentTypes
 */
export async function requestReadEssay(programId: string, studentTypes: string[]): Promise<IRequestReadResponse> {
  // const { stageId, requestId } = formDataDTO;
  const url = `${API_URL}/programs/${programId}/essays/request`;
  try {
    const response = await axios.post(url, {
      studentTypes,
    });
    const data = responseParser(response);
    return data;
  } catch (err) {
    throw createError(err);
  }
}
