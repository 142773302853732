import { Router } from '@reach/router';
import React from 'react';

import SEO from '../components/seo';
import PublicLayout from '../layout/public-layout';
import ShareProgram from '../containers/share-program';

export default function ShareProgramPage() {
  return (
    <PublicLayout>
      <SEO title="Program" />
      <Router>
        <ShareProgram path="/programs/:programId" />
      </Router>
    </PublicLayout>
  );
}
